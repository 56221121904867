import React from "react"

import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics";

import '../styles/App.scss';

import discord from '../images/Discord-Logo-Wordmark-White.svg';

export default function BotBar(props) {
    return (
    <div className="BotBanner">
      <OutboundLink target="_blank" rel="noopener noreferrer" className="SubtleLink LinkButton" href="https://discord.gg/idleon">Join the <img className="DiscordButton" src={discord} alt=""/></OutboundLink>
      <div className="leftGroup smallStack">
        <Link className="SubtleLink LinkButton" to="/moreGames/">More Games</Link>
        <Link className="SubtleLink LinkButton" to="/privacypolicy/">Privacy Policy</Link>
        <Link className="SubtleLink LinkButton" to="/licenses/">Licenses</Link>
      </div>
    </div>)
}
