import React from "react"

import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics";

import '../styles/App.scss';

import discord from '../images/Discord-Logo-Wordmark-White.svg';
import discordIcon from '../images/Discord-Logo-White.svg';
import homeIcon from '../images/home-24px.svg';
import playIcon from '../images/play_arrow-24px.svg';
import starIcon from '../images/signal_cellular_alt-24px.svg';

export default function TopBar(props) {

    const selectedClass = "SubtleLink LinkButton SelectedButton";
    const regularClass = "SubtleLink LinkButton"

    return (
    <div className="TopBanner">
      <div className="leftGroup BigOnly">
        <Link className={props.selected === "index" ? selectedClass : regularClass} to="/">Home</Link>
        <OutboundLink target="_blank" rel="noopener noreferrer" className="SubtleLink LinkButton" href="https://discord.gg/idleon"><img className="DiscordButton" src={discord} alt=""/></OutboundLink>
      </div>
      <div className="leftGroup SmolOnly">
        <Link className={props.selected === "index" ? selectedClass : regularClass} to="/">
            <img className="DiscordButton" src={homeIcon} alt=""/>
            </Link>
        <OutboundLink target="_blank" rel="noopener noreferrer" className="SubtleLink LinkButton" href="https://discord.gg/idleon">
            <img className="DiscordButton" src={discordIcon} alt=""/>
        </OutboundLink>
      </div>
      <a className="SubtleLink PlayButton" href="/ytGl5oc/">Play for Free</a>
    </div>)
}

    //   <div className="leftGroup">
    //     <Link className={props.selected === "index" ? selectedClass : regularClass} to="/">
    //         <img className="DiscordButton" src={homeIcon} alt=""/>
    //         </Link>
    //     <Link className={props.selected === "leaderboards" ? selectedClass : regularClass} to="/leaderboards/">
    //         <img className="DiscordButton" src={starIcon} alt=""/>
    //         </Link>
    //     <OutboundLink className="SubtleLink LinkButton" href="https://discord.gg/idleon">
    //         <img className="DiscordButton" src={discordIcon} alt=""/>
    //     </OutboundLink>
    //   </div>
    //   <Link className="SubtleLink PlayButton" to="/game/"><img className="DiscordButton" src={playIcon} alt=""/></Link>